/* eslint-disable react/jsx-no-literals */
import React from 'react'
import styles from './CardReview.module.css'
import globalStyles from '../../../index.module.css'
import starImg from '../assets/svg/star.svg'
import blueTick from '../assets/svg/tick.svg'
import test1Img from '../assets/jpg/testimony1.jpg'
import test2Img from '../assets/jpg/testimony2.jpg'
import test3Img from '../assets/jpg/testimony3.jpg'

/**
 * CardReview component Dynamic card review
 * @param {Object} testimony Testimony data
 * @param {String} testimony.name person's name
 * @param {String} testimony.age person's age
 * @param {String} testimony.summary person's summary
 * @param {String} testimony.review person's review
 * @param {String} testimony.jpg person's image to use
 * @returns {Void} CardReview component
 */
const CardReview = ({ testimony }) => {
  const nameClasses = [styles.Name, styles.NameStars]
  const nameAge = `${testimony.name}, ${testimony.age}`
  const stars = [
    1,
    2,
    3,
    4,
    5
  ]
  let profilePicUrl = null
  switch (testimony.jpg) {
  case 'testimony1':
    profilePicUrl = test1Img
    break

  case 'testimony2':
    profilePicUrl = test2Img
    break

  case 'testimony3':
    profilePicUrl = test3Img
    break

  default:
    break
  }
  return (
    <div className={styles.Card}>
      {/* Photo, name, age heading */}
      <div className={styles.HeadContainer}>
        <div className={globalStyles.TripleColumn}>
          <div className={styles.PhotoName}>
            <img
              src={profilePicUrl}
              alt={`profile-${testimony.name}`}
              className={styles.ProfilePicture}
            />
            <div className={nameClasses.join(' ')}>
              <div>{nameAge}</div>
              <div className={globalStyles.Row}>
                {
                  stars.map((starElem, index) => (
                    <img
                      key={starElem}
                      src={starImg}
                      alt={`star-${index}`}
                      className={styles.Star}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            src={blueTick}
            alt="blue-approve"
            className={styles.Check}
          />
        </div>
      </div>
      {/* Review - summary */}
      <div className={styles.ReviewRow}>
        <div className={styles.ReviewColumn}>
          <div className={styles.Summary}>
            {testimony.summary}
          </div>
          {/* Review */}
          <div className={styles.Review}>
            {testimony.review}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardReview
