/* eslint-disable max-statements */
import React, { useState, useContext } from 'react'
import styles from './HowItWorks.module.css'
import globalStyles from '../../index.module.css'
import content from './HowItWorks.content'
import * as constants from '../../Utils/constants'
import CandidateMobile from './CandidateMobile/CandidateMobile'
import CandidateDesktop from './CandidateDesktop/CandidateDesktop'
import PossibleAdvancedDesktop from './PossibleAdvancedDesktop/PossibleAdvancedDesktop'
import { CustomerContext } from '../../context/context'

/**
 * HowItWorks component
 * @returns {Void} HowItWorks component
 */
const HowItWorks = () => {
  const [isPossAdvComplex, setIsPossAdvComplex] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { patientType } = customer

  let displayedContent = null
  const mainClasses = []
  switch (patientType) {
  case constants.MOONS:
  case constants.CANDIDATE:
    mainClasses.push(globalStyles.Row, styles.MainContent)
    displayedContent = (
      <div className={mainClasses.join(' ')}>
        <div className={globalStyles.Column}>
          <div className={styles.Heading}>
            {content.heading}
          </div>
          <CandidateMobile />
          <CandidateDesktop />
        </div>
      </div>
    )
    break

  case constants.MOONS_ADVANCED:
    mainClasses.push(globalStyles.Row, styles.MainContent)
    displayedContent = (
      <div className={mainClasses.join(' ')}>
        <div className={globalStyles.Column}>
          <div className={styles.Heading}>
            {content.heading}
          </div>
          <CandidateMobile isComplex />
          <CandidateDesktop isComplex />
        </div>
      </div>
    )
    break

  case constants.MOONS_POSSIBLE_ADVANCED:
    {
      mainClasses.push(globalStyles.Row, styles.MainContent, styles.MainPossAdv)
      const moderateClasses = [styles.SwitchButton, styles.ModerateButton]
      const complexClasses = [styles.SwitchButton, styles.ComplexButton]
      if (isPossAdvComplex) {
        complexClasses.push(styles.ActiveButton)
      } else {
        moderateClasses.push(styles.ActiveButton)
      }
      const moderateBtnRef = React.createRef()
      const complexBtnRef = React.createRef()
      displayedContent = (
        <>
          <div className={mainClasses.join(' ')}>
            <div className={globalStyles.Column}>
              <div className={styles.Heading}>
                {content.heading}
              </div>
              <div className={styles.ComplexityContainer}>
                <button
                  type="button"
                  className={moderateClasses.join(' ')}
                  ref={moderateBtnRef}
                  onClick={() => {
                    setIsPossAdvComplex(false)
                    moderateBtnRef.current.blur()
                  }}
                >
                  {content.moderate}
                </button>
                <button
                  type="button"
                  className={complexClasses.join(' ')}
                  ref={complexBtnRef}
                  onClick={() => {
                    setIsPossAdvComplex(true)
                    complexBtnRef.current.blur()
                  }}
                >
                  {content.complex}
                </button>
              </div>
              {
                isPossAdvComplex
                  ? (
                    <CandidateMobile
                      isComplex
                      showDesktop
                    />
                  )
                  : <CandidateMobile showDesktop />
              }
            </div>
          </div>
          <PossibleAdvancedDesktop />
        </>
      )
    }
    break

  default:
    break
  }

  return (
    <>
      {displayedContent}
    </>
  )
}

export default HowItWorks
