/* eslint-disable max-params */
/* eslint-disable global-require */
/* eslint-disable callback-return */
import { createRef } from 'react'

/**
 * DynamicRef Function
 * @param {String} total .
 * @return {void}
 */
const dynamicRef = (total) => {
  const refs = []
  for (let initial = 0; initial < total; initial++) {
    refs[initial] = createRef()
  }
  return refs
}

/**
 * Get Style Item By Property
 * @param {String} node .
 * @param {String} property .
 * @return {void}
 */
const getStyleItemByProperty = (node, property) => {
  const nodeStyle = window.getComputedStyle(node)
  const styles = parseInt(nodeStyle.getPropertyValue(property), 10)
  return styles
}

export { dynamicRef, getStyleItemByProperty }
