import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import ErrorPage from '@mymoons/error-page'
import Diagnostic from './Components/Diagnostic/Diagnostic'
import CustomerProvider from './context/context'
import './App.css'

/**
 * App component
 * @returns {JSX.Element} App component
 */
const App = () => (
  <CustomerProvider>
    <BrowserRouter>
      <Switch>
        <Route
          path="/:id"
          component={() => (<Diagnostic />)}
        />
        <Route
          component={() => (<ErrorPage />)}
        />
      </Switch>
    </BrowserRouter>
  </CustomerProvider>
)

export default App
