import React from 'react'
import style from './TestimonialsCarousel.module.css'
import Mobile from './Mobile/Mobile'
import Tablet from './Tablet/Tablet'

/**
 * Testimonials Carousel Component
 * @return {void} Testimonials Carousel Component
 */
const TestimonialsCarousel = ({ info }) => (
  <div className={style.CarouselContainer}>
    <Mobile info={info} />
    <Tablet info={info} />
  </div>
)

export default TestimonialsCarousel
