import React from 'react'
import styles from './Header.module.css'
import moonsLogo from '../../Assets/images/svg/logo.svg'

/**
 * Header component
 * @returns {Void} Header component
 */
const Header = () => (
  <header className={styles.HeaderContainer}>
    <div className={styles.Logo}>
      <img
        src={moonsLogo}
        alt="moons logo"
      />
    </div>
  </header>
)

export default Header
