import React, { useContext } from 'react'
import styles from './CaseDetail.module.css'
import globalStyles from '../../index.module.css'
import content from './CaseDetail.content'
import * as constants from '../../Utils/constants'
import { CustomerContext } from '../../context/context'

/**
 * CaseDetail component
 * @returns {Void} CaseDetail component
 */
const CaseDetail = () => {
  const { customer } = useContext(CustomerContext)
  const { patientType, detailData, currency } = customer

  let displayedContent = null
  switch (patientType) {
  case constants.MOONS:
    displayedContent = (
      <div className={styles.MainContent}>
        <div className={globalStyles.Row}>
          <div className={globalStyles.Column}>
            <div className={styles.DetailContainer}>
              <div className={styles.Detail}>
                {content[patientType].detail}
              </div>
            </div>
            <div className={styles.Price}>
              {content[patientType].price.replace('[price]', detailData.finalPrice)}
              {content.currency.replace('[currency]', currency)}
            </div>
          </div>
        </div>
      </div>
    )
    break

  case constants.MOONS_ADVANCED:
    displayedContent = (
      <div className={styles.MainContent}>
        <div className={globalStyles.Row}>
          <div className={globalStyles.Column}>
            <div className={styles.DetailContainer}>
              <div className={styles.Detail}>
                {content.moons_advanced.detail}
              </div>
            </div>
            <div className={styles.Price}>
              {content.moons_advanced.price.replace('[price]', detailData.finalPrice)}
              {content.currency.replace('[currency]', currency)}
            </div>
          </div>
        </div>
      </div>
    )
    break

  case constants.NO_CANDIDATE:
    displayedContent = detailData.drNotes && (
      <div className={styles.MainContent}>
        <div className={globalStyles.Row}>
          <div className={globalStyles.Column}>
            <div className={styles.DetailContainerNoCand}>
              <div className={styles.Detail}>
                {content.no_candidate.detail}
              </div>
              <div className={styles.Detail}>
                {content.no_candidate.notes.replace('[drNotes]', detailData.drNotes)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
    break

  case constants.NEED_MEDICAL_TREATMENT:
    {
      const mainClasses = [globalStyles.Row, styles.NeedMedContainer]
      const headClasses = [styles.Price, styles.NeedMedPriceContainer]
      displayedContent = (
        <div className={mainClasses.join(' ')}>
          <div className={globalStyles.Column}>
            <div className={headClasses.join(' ')}>
              {content.need_medical_treamnet.detail}
            </div>
            <div className={styles.NeedMedDetailContainer}>
              <div className={styles.Detail}>
                {content.need_medical_treamnet.first}
              </div>
              <div className={styles.Detail}>
                {content.need_medical_treamnet.second}
              </div>
            </div>
          </div>
        </div>
      )
    }
    break

  case constants.MOONS_POSSIBLE_ADVANCED:
    displayedContent = (
      <div className={styles.MainContent}>
        <div className={globalStyles.Row}>
          <div className={globalStyles.Column}>
            <div className={styles.DetailContainer}>
              <div className={styles.Detail}>
                {content.moons_possible_advanced.detail}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.PossAdvPrices}>
          <div className={styles.ModPrice}>
            <div className={styles.Detail}>
              {content.moons_possible_advanced.moderate}
            </div>
            <div className={styles.Price}>
              {content.moons_possible_advanced.modPrice.replace('[modPrice]', detailData.moderatePrice)}
              {content.currency.replace('[currency]', currency)}
            </div>
          </div>
          <div className={styles.ComplexPrice}>
            <div className={styles.Detail}>
              {content.moons_possible_advanced.complex}
            </div>
            <div className={styles.Price}>
              {content.moons_possible_advanced.complexPrice.replace('[complexPrice]', detailData.complexPrice)}
              {content.currency.replace('[currency]', currency)}
            </div>
          </div>
        </div>
      </div>
    )
    break

  default:
    break
  }

  return displayedContent
}

export default CaseDetail
