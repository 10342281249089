import React from 'react'
import styles from './Disclaimer.module.css'
import content from './Disclaimer.content'

/**
 * Disclaimer comp
 * @param {Boolean} isHomeKit whether is Home Kit to present text
 * @returns {Void} Disclaimer comp
 */
const Disclaimer = ({ isHomeKit }) => (
  <div className={styles.MainContainer}>
    <div className={styles.Text}>
      {
        isHomeKit
          ? (
            <>
              {content.textKit}
              <strong style={{ fontWeight: '800' }}>{content.bold}</strong>
              {content.textKit2}
            </>
          )
          : (
            <>
              {content.textAppnt}
              <strong style={{ fontWeight: '800' }}>{content.bold}</strong>
              {content.textAppnt2}
            </>
          )
      }
    </div>
  </div>
)

export default Disclaimer
