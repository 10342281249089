import React from 'react'
import styles from './PossibleAdvancedDesktop.module.css'
import globalStyles from '../../../index.module.css'
import content from '../HowItWorks.content'
import CandidateMobile from '../CandidateMobile/CandidateMobile'

/**
 * PossibleAdvancedDesktop component
 * @returns {Void} PossibleAdvancedDesktop component
 */
const PossibleAdvancedDesktop = () => (
  <div className={styles.MainContainer}>
    <div className={globalStyles.Row}>
      <div className={globalStyles.Column}>
        <div className={styles.Heading}>
          {content.heading}
        </div>
      </div>
    </div>
    <div className={globalStyles.Row}>
      <div className={styles.Container}>
        <div className={styles.ModerateContainer}>
          <CandidateMobile showDesktop />
        </div>
        <div className={styles.ComplexContainer}>
          <CandidateMobile
            isComplex
            showDesktop
          />
        </div>
      </div>
    </div>
  </div>
)

export default PossibleAdvancedDesktop
