import React, { useEffect } from 'react'
import styles from './AuxSticky.module.css'

/**
 * AuxSticky component to provide an intersection platform
 * @param {Function} handleIsSticky Hanlder to change is sticky state in parent
 * @returns {JSX.Element} AuxSticky component
 */
const AuxSticky = ({ handleIsSticky }) => {
  const stickyRef = React.createRef()
  useEffect(() => {
    const cachedRef = stickyRef.current
    const observer = new IntersectionObserver(
      ([entry]) => handleIsSticky(entry.isIntersecting),
      { threshold: [0], rootMargin: '0px 0px -32px 0px' }
    )

    observer.observe(cachedRef)

    // unmount
    return () => {
      observer.unobserve(cachedRef)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      ref={stickyRef}
      className={styles.AuxContainer}
    />
  )
}

export default AuxSticky
