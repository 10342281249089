import React, { useContext } from 'react'
import styles from './Warning.module.css'
import globalStyles from '../../index.module.css'
import content from './Warning.content'
import * as constants from '../../Utils/constants'
import { CustomerContext } from '../../context/context'

/**
 * Warning component
 * @param {Boolean} [isMoonsKit] Whether the warning will be used for moons kit alert
 * or diagnosis alert
 * @returns {JSX.Element} Warning component
 */
const Warning = ({ isMoonsKit }) => {
  const { customer } = useContext(CustomerContext)
  const { patientType, reasons } = customer

  let displayedContent = null
  const mainContClasses = [globalStyles.Row]
  const textClasses = [styles.MoonsPossAdvText, styles.MoonPossAdvContainer]
  const contentClasses = [globalStyles.TripleColumn]
  if (isMoonsKit) {
    mainContClasses.push(styles.MainContainerKit)
    textClasses.pop()
    displayedContent = (
      <div className={textClasses.join(' ')}>
        {content.moonsKit}
      </div>
    )
  } else {
    mainContClasses.push(styles.MainContainer)
    switch (patientType) {
    case constants.MOONS_POSSIBLE_ADVANCED:
      contentClasses.push(styles.MessageContainer)
      displayedContent = (
        <div className={textClasses.join(' ')}>
          {content.moons_possible_advanced}
        </div>
      )
      break

    case constants.MOONS_ADVANCED:
      contentClasses.push(styles.MessageContainer)
      displayedContent = (
        <div className={textClasses.join(' ')}>
          {content.moons_advanced}
        </div>
      )
      break

    case constants.NEED_MEDICAL_TREATMENT:
    case constants.NEED_EXTRA_INFO:
      contentClasses.push(styles.MessageListContainer)
      mainContClasses.push(styles.MainContainerList)
      displayedContent = reasons.length > 0
      && (
        <ul>
          {
            reasons.map((reason) => (
              <li
                key={reason}
                className={styles.ListItem}
              >
                {reason}
              </li>
            ))
          }
        </ul>
      )
      break

    default:
      break
    }
  }

  return (
    displayedContent
    && (
      <div className={mainContClasses.join(' ')}>
        <div className={globalStyles.Column}>
          <i className={styles.WarningIcon} />
        </div>
        <div className={contentClasses.join(' ')}>
          {displayedContent}
        </div>
      </div>
    )
  )
}

export default Warning
