import React from 'react'
import styles from './CandidateDesktop.module.css'
import content from '../HowItWorks.content'

/**
 * CandidateDesktop component
 * @param {Boolean} isComplex show Customer's case complexity
 * @returns {Void} CandidateDesktop
 */
const CandidateDesktop = ({ isComplex }) => {
  const one = '1'
  const two = '2'
  const three = '3'
  return (
    <div className={styles.MainContainer}>
      <div className={styles.FirstContent}>
        <ul className={styles.UnordList}>
          <li className={styles.ListItem}>{one}</li>
          <li className={styles.ListItem}>{two}</li>
          <li className={styles.ListItem}>{three}</li>
        </ul>
      </div>
      <div className={styles.SecondContent}>
        <div className={styles.ContentContainer}>
          <div className={styles.Title}>{content.first.title}</div>
          <div className={styles.Msg}>{content.first.msg}</div>
        </div>
        <div className={styles.ContentContainer}>
          <div className={styles.Title}>{content.second.title}</div>
          <div className={styles.Msg}>{content.second.msg}</div>
        </div>
        <div className={styles.ContentContainer}>
          <div className={styles.Title}>
            { isComplex
              ? content.thirdComplex.title
              : content.third.title
            }
          </div>
          <div className={styles.Msg}>
            { isComplex
              ? content.thirdComplex.msg
              : content.third.msg
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateDesktop
