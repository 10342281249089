/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import React, { useContext } from 'react'
import styles from './Refund.module.css'
import content from './Refund.content'
import PromoCode from '../PromoCode/PromoCode'
import { CustomerContext } from '../../context/context'

/**
 * Refund component view for no candidate case
 * shows paid amount to be refunded
 * share code promo
 * @param {Function} handleIsSticky Hanlder to change is sticky state in parent
 * @returns {Void} Refund component
 */
const Refund = ({ handleIsSticky }) => {
  const { customer } = useContext(CustomerContext)
  const { paidAmount, currency, discountCode } = customer
  const mainClasses = [styles.MainContainer]

  return (
    <div className={mainClasses.join(' ')}>
      {
        paidAmount && parseInt(paidAmount.replace('$', ''), 10) > 0
        && (
          <div className={styles.MessageContainer}>
            <div className={styles.Title}>
              {content.title}
            </div>
            <div className={styles.Msg}>
              {content.message}
            </div>
            <div className={styles.PaidAmount}>
              {content.paidAmount.replace('[paid]', paidAmount)}
              {content.currency.replace('[currency]', currency)}
            </div>
            <div className={styles.CustomerCare}>
              {content.customer}
            </div>
          </div>
        )
      }
      {/* promo content */}
      {
        discountCode && (
          <PromoCode handleIsSticky={handleIsSticky} />
        )
      }
    </div>
  )
}

export default Refund
