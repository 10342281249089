/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useContext, useState, useEffect } from 'react'
import styles from './DoctorStatement.module.css'
import content from './DoctorStatement.content'
import { CustomerContext } from '../../context/context'
import * as constants from '../../Utils/constants'
import drPrado from '../../Assets/images/png/drprado3x.png'
import LoaderSection from '../LoaderSection/LoaderSection'
import Middleware from '../../Api/Middleware'

/**
 * Doctor Statement component
 * @returns {Void} DoctorStatement component
 */
const DoctorStatement = () => {
  const { customer } = useContext(CustomerContext)
  const { patientType, alignersRange, diagnosedBy } = customer

  const [error, setError] = useState(false)
  const [drInfo, setDrInfo] = useState({})

  useEffect(() => {
    const requestBody = {
      table: constants.ORTHO_CLASS,
      columns: {
        name: diagnosedBy,
        active: true
      }
    }
    Middleware.sendRequest(requestBody, setDrInfo, setError, true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    setDrInfo({
      photo: drPrado,
      gender: 'male',
      name: 'José Prado',
      imgClass: ' '
    })
    setError(false)
  }

  if (Object.keys(drInfo).length <= 0) {
    return (
      <div className={styles.LoaderContainer}>
        <LoaderSection />
      </div>
    )
  }

  let doctorMsg = null
  const primClasses = []
  const secondClasses = []
  switch (patientType) {
  case constants.MOONS:
  case constants.MOONS_ADVANCED:
    primClasses.push(styles.DrText, styles.PrimaryTextMoons)
    secondClasses.push(styles.DrText, styles.SecondaryTextMoons)
    doctorMsg = (
      <>
        <div className={styles.Column}>
          <img
            src={drInfo.photo}
            alt="dr prado"
            className={[styles.DrPrado, drInfo.imgClass || styles.BorderDrImg].join(' ')}
          />
        </div>
        <div className={styles.DoubleColumn}>
          <div className={primClasses.join(' ')}>
            {content[constants.MOONS].initial.replace('{dr_gender}', drInfo.gender === 'male' ? 'El doctor' : 'La doctora')
              .replace('{dr_name}', drInfo.name)}
            <strong style={{ fontWeight: '800' }}>
              {content[constants.MOONS].bold}
            </strong>
          </div>
          <div className={secondClasses.join(' ')}>
            {content[constants.MOONS].secondary}
            <br />
            <strong style={{ fontWeight: '800' }}>{alignersRange}</strong>
          </div>
        </div>
      </>
    )
    break

  case constants.MOONS_POSSIBLE_ADVANCED:
    primClasses.push(styles.DrText, styles.PrimaryTextMoonsAdv)
    doctorMsg = (
      <>
        <div className={styles.Column}>
          <img
            src={drInfo.photo}
            alt="dr prado"
            className={[styles.DrPrado, drInfo.imgClass || styles.BorderDrImg].join(' ')}
          />
        </div>
        <div className={styles.DoubleColumn}>
          <div
            className={primClasses.join(' ')}
            style={{ margin: '32px 0px 32px 0' }}
          >
            {content[constants.MOONS].initial.replace('{dr_gender}', drInfo.gender === 'male' ? 'El doctor' : 'La doctora')
              .replace('{dr_name}', drInfo.name)}
            <strong style={{ fontWeight: '800' }}>
              {content[constants.MOONS].bold}
            </strong>
          </div>
        </div>
      </>
    )
    break

  case constants.NO_CANDIDATE:
    primClasses.push(styles.DrText, styles.PrimaryTextNoCand)
    secondClasses.push(styles.DrText, styles.SecondaryTextNoCand)
    doctorMsg = (
      <>
        <div className={styles.Column}>
          <img
            src={drInfo.photo}
            alt="dr prado"
            className={[styles.DrPrado, drInfo.imgClass || styles.BorderDrImg].join(' ')}
          />
        </div>
        <div className={styles.DoubleColumn}>
          <div className={primClasses.join(' ')}>
            {content.no_candidate.initial.replace('{dr_gender}', drInfo.gender === 'male' ? 'El doctor' : 'La doctora')
              .replace('{dr_name}', drInfo.name)}
            <span style={{ fontWeight: '800' }}>
              {content.no_candidate.bold}
            </span>
            {content.no_candidate.initial2}
          </div>
          <div className={secondClasses.join(' ')}>
            {content.no_candidate.secondary}
          </div>
        </div>
      </>
    )
    break

  case constants.NEED_MEDICAL_TREATMENT:
    secondClasses.push(styles.DrText, styles.SecondaryTextNeedMed)
    doctorMsg = (
      <>
        <div className={styles.Column}>
          <img
            src={drInfo.photo}
            alt="dr prado"
            className={[styles.DrPrado, drInfo.imgClass || styles.BorderDrImg].join(' ')}
          />
        </div>
        <div className={styles.DoubleColumn}>
          <div className={secondClasses.join(' ')}>
            {content.need_medical_treatment.initial.replace('{dr_gender}', drInfo.gender === 'male' ? 'El doctor' : 'La doctora')
              .replace('{dr_name}', drInfo.name)}
          </div>
        </div>
      </>
    )
    break

  case constants.NEED_EXTRA_INFO:
    secondClasses.push(styles.DrText, styles.SecondaryTextNeedMed)
    doctorMsg = (
      <>
        <div className={styles.Column}>
          <img
            src={drInfo.photo}
            alt="dr prado"
            className={[styles.DrPrado, drInfo.imgClass || styles.BorderDrImg].join(' ')}
          />
        </div>
        <div className={styles.DoubleColumn}>
          <div className={secondClasses.join(' ')}>
            {content.need_extra_info.initial.replace('{dr_gender}', drInfo.gender === 'male' ? 'El doctor' : 'La doctora')
              .replace('{dr_name}', drInfo.name)}
          </div>
        </div>
      </>
    )
    break

  case constants.CANDIDATE:
    primClasses.push(styles.DrText, styles.PrimaryTextMoons)
    secondClasses.push(styles.DrText, styles.SecondaryTextMoons)
    doctorMsg = (
      <>
        <div className={styles.Column}>
          <img
            src={drInfo.photo}
            alt="dr prado"
            className={[styles.DrPrado, drInfo.imgClass || styles.BorderDrImg].join(' ')}
          />
        </div>
        <div className={styles.DoubleColumn}>
          <div className={primClasses.join(' ')}>
            {content.candidate.initial.replace('{dr_gender}', drInfo.gender === 'male' ? 'El doctor' : 'La doctora')
              .replace('{dr_name}', drInfo.name)}
            <strong style={{ fontWeight: '800' }}>
              {content.candidate.bold}
            </strong>
          </div>
          <div className={secondClasses.join(' ')}>
            {content.candidate.secondary}
          </div>
        </div>
      </>
    )
    break

  case constants.DEFAULT_CASE:
    primClasses.push(styles.DrText, styles.PrimaryTextMoons)
    secondClasses.push(styles.DrText, styles.SecondaryTextMoons)
    doctorMsg = (
      <>
        <div className={primClasses.join(' ')}>
          {content.default.initial}
        </div>
        <div className={secondClasses.join(' ')}>
          {content.default.secondary}
        </div>
      </>
    )
    break

  default: break
  }

  const mainContClasses = [styles.Row, styles.MainContainer]

  return (
    <div className={mainContClasses.join(' ')}>
      {doctorMsg}
    </div>
  )
}

export default DoctorStatement
