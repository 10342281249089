/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undefined */
import React, { createRef } from 'react'
import style from './Mobile.module.css'
import { dynamicRef, getStyleItemByProperty } from '../utils'
import CardReview from '../CardReview/CardReview'

/**
 * Mobile Component
 * @return {void}
 */
class Mobile extends React.Component {
  /**
   * Constructor
   * @param {Object} props .
   * @return {void}
   */
  constructor(props) {
    super(props)

    this.curLeft = 0
    this.moveX = 0
    this.startX = 0
    this.curSlide = 0
    this.loadedCnt = 0
    this.slideW = 0
    this.totalSlides = props.info.length

    this.refSlider = createRef()
    this.refSliderContainer = createRef()
    this.refAllSlide = dynamicRef(this.totalSlides)
    this.refDots = createRef()

    this.def = {
      transition: {
        speed: 300,
        easing: ''
      },
      swipe: true,
      autoHeight: false,
      afterChangeSlide: () => { }
    }
    this.state = {
      isChanging: false,
    }
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount() {
    window.addEventListener('resize', this.updateSliderDimension)
    this.init()
    this.updateSliderDimension()
  }

  /**
   * Component Did Update
   * @param {Object} nextProps .
   * @param {Object} nextState .
   * @return {void}
   */
  componentDidUpdate(nextProps, nextState) {
    const { isChanging } = this.state
    if (isChanging !== nextState.isChanging && isChanging) {
      this.changeDimentionImage()
    }
  }

  /**
   * Component Will Un Mount
   * @return {void}
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSliderDimension)
  }

  /**
   * Go to Slide
   * @param {String} step .
   * @return {void}
   */
  gotoSlide = (step) => {
    if (step !== undefined) {
      this.curSlide = step
    }
    this.refSliderContainer.current.style.transition = `left ${this.def.transition.speed / 1000}s ${this.def.transition.easing}`
    this.refSliderContainer.current.style.left = `${-this.curSlide * (this.slideW - 3 * this.slideMargin)}px`
    setTimeout(() => {
      this.refSliderContainer.current.style.transition = ''
    }, this.def.transition.speed)
    this.setDot()
    this.setState({
      isChanging: true
    })
  }

  /**
   * Set Dot
   * @return {void}
   */
  setDot = () => {
    const { children } = this.refDots.current
    for (let initial = 0; initial < children.length; initial++) {
      if (initial === this.curSlide) {
        children[initial].classList.add(style.SliderActive)
        children[initial].classList.remove(style.SliderBotton)
      } else {
        children[initial].classList.add(style.SliderBotton)
        children[initial].classList.remove(style.SliderActive)
      }
    }
  }

  /**
   * Start Move
   * @param {Object} event .
   * @return {void}
   */
  startMove = (event) => {
    this.getCurrentLeft()
    const touch = event.targetTouches[0] || event.changedTouches[0]
    this.startX = touch.pageX
  }

  /**
   * Moving
   * @param {Object} event .
   * @return {void}
   */
  Moving = (event) => {
    const touch = event.targetTouches[0] || event.changedTouches[0]
    this.moveX = touch.pageX

    if (Math.abs(this.moveX - this.startX) < 40) return

    this.refSliderContainer.current.style.left = `${this.curLeft + this.moveX - this.startX}px`
  }

  /**
   * End Move
   * @return {void}
   */
  endMove = () => {
    this.getCurrentLeft()

    if (Math.abs(this.moveX - this.startX) === 0) return

    const stayAtCur = !!(Math.abs(this.moveX - this.startX) < 40 || this.moveX === 0)
    const dir = this.startX < this.moveX ? 'left' : 'right'

    if (!stayAtCur) {
      dir === 'left' ? this.curSlide -= 1 : this.curSlide += 1
      if (this.curSlide < 0) {
        this.curSlide = 0
      } else if (this.curSlide === this.totalSlides) {
        this.curSlide -= 1
      }
    }

    this.gotoSlide()
    this.restValues()
  }

  restValues = () => {
    this.startX = 0
    this.moveX = 0
  }

  getCurrentLeft = () => {
    const { left } = this.refSliderContainer.current.style
    if (left) this.curLeft = parseInt(left, 10)
  }

  /**
   * Change Dimention Image
   * @param {String} clear .
   * @return {void}
   */
  changeDimentionImage = (clear) => {
    this.refAllSlide.forEach((child, index) => {
      if (clear) {
        child.current.style.transform = ''
      } else if (this.curSlide === index) {
        child.current.style.transform = 'scale(1)'
        if (this.state.isChanging) this.setState({ isChanging: false })
      } else if (this.state.isChanging) {
        child.current.style.transform = 'scale(0.9)'
        this.setState({ isChanging: false })
      }
    })
  }

  /**
   * Update Slider Dimension
   * @return {void}
   */
  updateSliderDimension = () => {
    if (this.refSlider && this.refSlider.current && this.refSlider.current.style) {
      this.refSlider.current.style.left = `${-this.slideW * this.curSlide}px`
    }
    const out = 18.5 * window.innerWidth / 100
    this.refSlider.current.style.width = `${(window.innerWidth - out) * this.totalSlides}px`

    this.changeDimentionImage(true)
    this.getSlideW()
    this.changeDimentionImage()
  }

  getSlideW = () => {
    const allSlider = this.refAllSlide
    if (allSlider.length > 0 && allSlider[this.curSlide] && allSlider[this.curSlide].current) {
      const node = allSlider[this.curSlide].current
      this.slideW = parseInt(node.offsetWidth, 10)
      this.slideMargin = getStyleItemByProperty(allSlider[this.curSlide].current, 'margin-right')
    } else {
      this.slideW = 0
    }
  }

  init = () => {
    this.refSliderContainer.current.style.left = '0px'
    this.setDot()
    this.getSlideW()
  }

  /**
   * Render
   * @return {void}
   */
  render() {
    const { info } = this.props
    return (
      <div className={style.CarouselMovil}>
        <div className={style.CarouselHeader}>
          <div className={style.HeaderTitle}>{info[0].header}</div>
        </div>
        <div
          ref={this.refSliderContainer}
          onTouchStart={(event) => this.startMove(event)}
          onTouchMove={(event) => this.Moving(event)}
          onTouchEnd={(event) => this.endMove(event)}
          className={style.SlideshowContainer}
        >
          <div
            ref={this.refSlider}
            className={style.SliderMove}
          >
            {info.map((item, index) => (
              <div
                key={index}
                ref={this.refAllSlide[index]}
                className={style.StepContainerImages}
              >
                <CardReview testimony={item} />
              </div>
            ))}
          </div>
        </div>
        <div
          ref={this.refDots}
          className={style.SliderBootOut}
        >
          {info.map((item, index) => (
            <button
              key={index}
              type="button"
              onClick={() => { this.gotoSlide(index) }}
            />
          ))}
        </div>
      </div>
    )
  }
}
export default Mobile
