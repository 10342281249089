const templateContent = {
  heading: '¿Cómo funciona?',
  first: {
    title: 'Incluye diseño de sonrisa',
    msg: 'Te mostramos tu futura sonrisa, antes de iniciar tu tramiento, con una simulación 3D'
  },
  second: {
    title: 'Tratamiento completo',
    msg: 'Recibirás todos los alienadores necesarios para tu tratamiento.'
  },
  third: {
    title: 'Ortodoncista remoto incluido',
    msg: 'Un ortodoncista da seguimiento a tu caso'
  },
  thirdComplex: {
    title: 'Seguimiento presencial',
    msg: 'Un ortodoncista dará seguimiento periódico a tu tratamiento en una cita en consultorio.'
  },
  moderate: 'Moderado',
  complex: 'Complejo'
}

export default templateContent
