const promoCodeContent = {
  promo: 'Regala [price] a tus amigos',
  promo2: 'y gana [price] con moons',
  promoMx: 1000,
  promoCo: 169607,
  description: 'Comparte tu código y recibe [price] en Amazon cuando tus amigos paguen su tratamiento, y ellos recibirán [price2] de descuento.',
  label: 'Tu código',
  button: 'Copiar',
  note: '*te lo enviamos a tu mail también',
  whatsapp: 'WhatsApp',
  facebook: 'Facebook',
  terms: 'Consulta términos y condiciones',
  whatsappLink: 'https://api.whatsapp.com',
  facebookLink: 'https://www.facebook.com/',
  termsMxUrl: 'https://mymoons.mx/terminos-y-condiciones-referidos',
  termsCoUrl: 'https://mymoons.co/terminos-y-condiciones-referidos'
}

export default promoCodeContent
