const callToActionContent = {
  book: 'AGENDAR CITA',
  contact: 'CONTACTAR',
  no_candidate: 'No soy candidato, quiero información sobre mi devolución',
  need_medical_treatment: 'Para mi diagnóstico necesito tratamiento extra, quiero mas información',
  need_extra_info: 'Para mi diagnóstico necesitan más información, necesito más información',
  ctaBookText: 'Agenda tu cita ahora para comenzar tu tratamiento',
  candidate: 'AGENDAR MI CITA'
}

export default callToActionContent
