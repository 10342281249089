import React from 'react'
import styles from './CandidateMobile.module.css'
import content from '../HowItWorks.content'

/**
 * CanidateMovile component
 * @param {Boolean} isComplex show Customer's case complexity
 * @param {Boolean} showDesktop flag to show mobile content for moons possible advanced case
 * @returns {Void} CandidateMobile component
 */
const CandidateMobile = ({ isComplex, showDesktop }) => {
  const mainClasses = [styles.Unordered]
  if (showDesktop) {
    mainClasses.push(styles.PossAdvDesktop)
  }
  return (
    <ul className={mainClasses.join(' ')}>
      <li className={styles.ListItem}>
        <div className={styles.Point} />
        <div className={styles.Content}>
          <div className={styles.Title}>{content.first.title}</div>
          <div className={styles.Msg}>{content.first.msg}</div>
        </div>
      </li>
      <li>
        <div className={styles.Point} />
        <div className={styles.Content}>
          <div className={styles.Title}>{content.second.title}</div>
          <div className={styles.Msg}>{content.second.msg}</div>
        </div>
      </li>
      <li>
        <div className={styles.Point} />
        <div className={styles.Content}>
          <div className={styles.Title}>
            { isComplex
              ? content.thirdComplex.title
              : content.third.title
            }
          </div>
          <div className={styles.Msg}>
            { isComplex
              ? content.thirdComplex.msg
              : content.third.msg
            }
          </div>
        </div>
      </li>
    </ul>
  )
}

export default CandidateMobile
