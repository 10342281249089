const caseDetailContent = {
  moons: {
    detail: 'Debido a la complejidad de tu caso el precio de tu tratamiento completo es:',
    price: '[price]'
  },
  no_candidate: {
    detail: 'No eres candidato porque:',
    notes: '[drNotes]'
  },
  need_medical_treamnet: {
    detail: 'Necesitamos enviarte con uno de nuestros ortodoncistas moons',
    first: 'Nuestro equipo de customer care te contactará en breve para explicarte el detalle los siguientes pasos.',
    second: 'No olvides que también nos puedes contactar.'
  },
  moons_possible_advanced: {
    detail: 'De acuerdo al resultado de tu escaneo, tu caso puede ser:',
    moderate: 'moderado',
    modPrice: '[modPrice]',
    complex: 'complejo',
    complexPrice: '[complexPrice]'
  },
  moons_advanced: {
    detail: 'Complejo',
    price: '[price]'
  },
  currency: ' [currency]'
}

export default caseDetailContent
