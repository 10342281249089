import React, { useState, useEffect, useContext } from 'react'
import styles from './Footer.module.css'
import {
  WHATSAPP_URL, FACEBOOK_MX, FACEBOOK_CO, WHATSAPP_COUNTRY_MX, WHATSAPP_COUNTRY_CO,
  INSTAGRAM_MX, INSTAGRAM_CO, YOUTUBE_URL, NO_CANDIDATE
} from '../../Utils/constants'
import news1Img from '../../Assets/images/png/news1.png'
import news2Img from '../../Assets/images/png/news2.png'
import news3Img from '../../Assets/images/png/news3.png'
import news4Img from '../../Assets/images/png/news4.png'
import news5Img from '../../Assets/images/png/news5.png'
import { CustomerContext } from '../../context/context'

/**
 * Footer component : includes reviews and contact links
 * @returns {Void} Footer component
 */
const Footer = () => {
  const { customer } = useContext(CustomerContext)
  const { patientType, country } = customer

  const [footerState, setFooterState] = useState({
    whatsapp: '',
    whatsappShow: '',
    facebook: '',
    instagram: '',
    youtube: ''
  })
  const showNews = patientType !== NO_CANDIDATE

  useEffect(() => {
    let newState = {}
    switch (country) {
    case 'Colombia':
    case 'colombia':
      newState = {
        whatsapp: encodeURIComponent(`${WHATSAPP_COUNTRY_CO}${process.env.REACT_APP_WHATS_CO}`),
        whatsappShow: process.env.REACT_APP_WHATS_CO,
        facebook: FACEBOOK_CO,
        instagram: INSTAGRAM_CO,
        youtube: YOUTUBE_URL
      }
      break

    default:
      newState = {
        whatsapp: encodeURIComponent(`${WHATSAPP_COUNTRY_MX}${process.env.REACT_APP_WHATS_MX}`),
        whatsappShow: process.env.REACT_APP_WHATS_MX,
        facebook: FACEBOOK_MX,
        instagram: INSTAGRAM_MX,
        youtube: YOUTUBE_URL
      }
      break
    }
    setFooterState(newState)
  }, [country])

  const contactClasses = [styles.Icons, styles.WhatsContainer]
  const whatsappClasses = [styles.Icons, styles.WhatsappIcon]
  const expansionClasses = showNews ? [styles.NewsImage, styles.News3] : []
  const techCrunchClasses = showNews ? [styles.NewsImage, styles.News5] : []
  const patientsClasses = showNews ? [styles.NewsImage, styles.News4] : []
  const forbesClasses = showNews ? [styles.NewsImage, styles.News2] : []
  const excelsiorClasses = showNews ? [styles.NewsImage, styles.News1] : []
  return (
    <div className={styles.MainContainer}>
      {
        showNews
        && (
          <div className={styles.NewsContainer}>
            <img
              src={news3Img}
              alt="news3"
              className={expansionClasses.join(' ')}
            />
            <img
              src={news5Img}
              alt="news5"
              className={techCrunchClasses.join(' ')}
            />
            <img
              src={news4Img}
              alt="news4"
              className={patientsClasses.join(' ')}
            />
            <img
              src={news2Img}
              alt="news2"
              className={forbesClasses.join(' ')}
            />
            <img
              src={news1Img}
              alt="news1"
              className={excelsiorClasses.join(' ')}
            />
          </div>
        )
      }
      <div className={styles.FooterContainer}>
        <div className={styles.Contact}>
          <a
            href={WHATSAPP_URL.replace('{moonsNum}', footerState.whatsapp)}
            target="_blank"
            rel="noopener noreferrer"
            className={contactClasses.join(' ')}
          >
            <div
              className={whatsappClasses.join(' ')}
            />
            {footerState.whatsappShow}
          </a>
        </div>
        <div className={styles.SocialNetworks}>
          <a
            href={footerState.facebook}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.Icons}
          >
            <i className={styles.FacebookIcon} />
          </a>
          <a
            href={footerState.instagram}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.Icons}
          >
            <i className={styles.InstagramIcon} />
          </a>
          <a
            href={footerState.youtube}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.Icons}
          >
            <i className={styles.YoutubeIcon} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
