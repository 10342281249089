import React, { useState, useEffect, useCallback } from 'react'
import styles from './CallToAction.module.css'
import globalStyles from '../../index.module.css'
import content from './CallToAction.content'
import apolloImg from '../../Assets/images/png/positive.png'

/**
 * CallToAction component Button for booking appointment or contacting through whatsapp
 * @param {Function} handleIsSticky Hanlder to change is sticky state in parent
 * @returns {JSX.Element} CallToAction component
 */
const CallToAction = ({ handleIsSticky }) => {
  /**
   * Handle change for boolea flag for is sticky
   * @param {Boolean} flagIsSticky whether the interaction observer fires
   * @returns {Boolean} whether it is sticky
   */
  const changeSticky = useCallback((flagIsSticky) => {
    setIsSticky(flagIsSticky)
    handleIsSticky(flagIsSticky)
  }, [handleIsSticky])

  const [isSticky, setIsSticky] = useState(false)
  const stickyRef = React.createRef()
  useEffect(() => {
    const cachedRef = stickyRef.current
    const observer = new IntersectionObserver(
      ([entry]) => changeSticky(entry.isIntersecting),
      { threshold: [0], rootMargin: '0px 0px -80px 0px' }
    )

    observer.observe(cachedRef)

    // unmount
    return () => {
      observer.unobserve(cachedRef)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.MainContainer}>
      <div className={styles.RowContainer}>
        <div className={globalStyles.Column}>
          <div
            className={styles.Title}
            ref={stickyRef}
          >
            {isSticky && content.title}
          </div>
        </div>
        <div className={globalStyles.Column}>
          <div className={styles.ApolloImgContainer}>
            <img
              src={apolloImg}
              alt="apollo-thumbs-up"
              className={styles.ApolloImg}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallToAction
