/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import React, { useEffect, useContext } from 'react'
import styles from './PromoCode.module.css'
import content from './PromoCode.content'
import promoImg from '../../Assets/images/png/share_code.png'
import { CustomerContext } from '../../context/context'
import { CURRENCY_MX } from '../../Utils/constants'
import { getStringCurrency } from '../../Utils/utils'

/**
 * copyToClipboard copies promo code to clipboard
 * @param {String} text string to copy
 * @returns {JSX.Element} copies to clipboard
 */
const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    // Prevent scrolling to bottom of page in Microsoft Edge.
    textarea.style.position = 'fixed'
    document.body.appendChild(textarea)
    textarea.select()
    try {
      // Security exception may be thrown by some browsers.
      return document.execCommand('copy')
    } catch (ex) {
      // console.log('Copy to clipboard failed.', ex)
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

/**
 * PromoCode component view for no candidate case
 * shows promo code and share
 * @param {Function} handleIsSticky Hanlder to change is sticky state in parent
 * @returns {JSX.Element} PromoCode component
 */
const PromoCode = ({ handleIsSticky }) => {
  const { customer } = useContext(CustomerContext)
  const { discountCode, currency } = customer
  const stickyRef = React.createRef()

  useEffect(() => {
    const cachedRef = stickyRef.current
    const heightSection = cachedRef && cachedRef.clientHeight
      ? cachedRef.clientHeight + 48
      : 92
    const observer = new IntersectionObserver(
      ([entry]) => handleIsSticky(entry.isIntersecting),
      {
        threshold: [0],
        rootMargin: `0px 0px -${heightSection}px 0px`
      }
    )

    observer.observe(cachedRef)

    // unmount
    return () => {
      observer.unobserve(cachedRef)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let promoQuantity = 0
  switch (currency) {
  case 'COP':
    promoQuantity = `${getStringCurrency(content.promoCo, currency)} ${currency}`
    break

  default:
    promoQuantity = `${getStringCurrency(content.promoMx, currency)} ${currency}`
    break
  }

  const whatsAppBtnClasses = [styles.CircleBtn, styles.WhatsappBtn]
  const facebookBtnClasses = [styles.CircleBtn, styles.FacebookBtn]

  return (
    <>
      <div className={styles.PromoContainer}>
        <div className={styles.Image}>
          <img
            src={promoImg}
            alt="promo-code-amazon"
          />
          <div className={styles.PromoText}>
            <span>{content.promo.replace('[price]', promoQuantity)}</span>
            <span>{content.promo2.replace('[price]', promoQuantity)}</span>
          </div>
        </div>
        <div className={styles.Description}>
          {content.description.replace('[price]', promoQuantity).replace('[price2]', promoQuantity)}
        </div>
      </div>
      <div className={styles.ShareContainer}>
        {/* clipboard copy promo code */}
        <div className={styles.ClipboardContainer}>
          <div>
            <div className={styles.YourCodeLabel}>
              {content.label}
            </div>
            <div className={styles.DiscountCode}>
              {discountCode}
            </div>
          </div>
          <div
            role="button"
            tabIndex="0"
            className={styles.CopyButton}
            onClick={() => copyToClipboard(discountCode)}
          >
            {content.button}
          </div>
        </div>
        {/* promo note */}
        <div className={styles.Note}>
          {content.note}
        </div>
        {/* social networks */}
        <div className={styles.SocialNetworks}>
          <div className={styles.Whatsapp}>
            <a
              href={content.whatsappLink}
              target="_blank"
              rel="noopener noreferrer"
              className={whatsAppBtnClasses.join(' ')}
            >
              <i className={styles.WhatsappIcon} />
            </a>
            <div className={styles.SocialNetLabel}>
              {content.whatsapp}
            </div>
          </div>
          <div className={styles.Facebook}>
            <a
              href={content.facebookLink}
              target="_blank"
              rel="noopener noreferrer"
              className={facebookBtnClasses.join(' ')}
            >
              <i className={styles.FacebookIcon} />
            </a>
            <div className={styles.SocialNetLabel}>
              {content.facebook}
            </div>
          </div>
        </div>
        <a
          href={currency === CURRENCY_MX ? content.termsMxUrl : content.termsCoUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.TermsCond}
          ref={stickyRef}
        >
          {content.terms}
        </a>
      </div>
    </>
  )
}

export default PromoCode
