import React, { useContext } from 'react'
import styles from './ApolloHeading.module.css'
import globalStyles from '../../index.module.css'
import moonsImg from '../../Assets/images/apollo/moons.svg'
import needMoreImg from '../../Assets/images/apollo/need_more.svg'
import noCandImg from '../../Assets/images/apollo/no_candidate.svg'
import * as constants from '../../Utils/constants'
import content from './ApolloHeading.content'
import { CustomerContext } from '../../context/context'

/**
 * ApolloHeading component
 * @returns {Void} ApolloHeading component
 */
const ApolloHeading = () => {
  const { customer } = useContext(CustomerContext)
  const { patientType, patientName } = customer

  let apolloImg = moonsImg
  let displayedContent = null
  const msgClasses = [styles.Message, globalStyles.Column]
  const descClasses = [styles.Description, globalStyles.Column]
  const apolloImgClasses = [styles.ApolloMoons]
  switch (patientType) {
  case constants.MOONS:
    msgClasses.push(styles.MessageMoons)
    descClasses.push(styles.DescMoons)
    displayedContent = (
      <>
        <div className={msgClasses.join(' ')}>
          {content[constants.MOONS].msg.replace('{name}', patientName)}
        </div>
        <div className={descClasses.join(' ')}>
          {content[constants.MOONS].description}
        </div>
      </>
    )
    break

  case constants.MOONS_POSSIBLE_ADVANCED:
  case constants.MOONS_ADVANCED:
    msgClasses.push(styles.MessageMoons)
    descClasses.push(styles.DescPossAdv)
    displayedContent = (
      <>
        <div className={msgClasses.join(' ')}>
          {content.possible_advanced.msg.replace('{name}', patientName)}
        </div>
        <div className={descClasses.join(' ')}>
          {content.possible_advanced.description}
        </div>
      </>
    )
    break

  case constants.NO_CANDIDATE:
    apolloImg = noCandImg
    msgClasses.push(styles.MessageNoCand)
    descClasses.push(styles.DescNoCand)
    apolloImgClasses.push(styles.ApolloMore)
    displayedContent = (
      <>
        <div className={msgClasses.join(' ')}>
          {content.no_candidate.msg}
        </div>
        <div className={descClasses.join(' ')}>
          {content.no_candidate.description}
        </div>
      </>
    )
    break

  case constants.NEED_MEDICAL_TREATMENT:
    apolloImg = needMoreImg
    apolloImgClasses.push(styles.ApolloMore)
    displayedContent = (
      <>
        <div className={msgClasses.join(' ')}>
          {content.need_medical_treatment.msg.replace('{name}', patientName)}
        </div>
        <div className={descClasses.join(' ')}>
          {content.need_medical_treatment.description}
        </div>
      </>
    )
    break

  case constants.NEED_EXTRA_INFO:
    apolloImg = needMoreImg
    apolloImgClasses.push(styles.ApolloMore)
    displayedContent = (
      <>
        <div className={msgClasses.join(' ')}>
          {content.need_extra_info.msg.replace('{name}', patientName)}
        </div>
        <div className={descClasses.join(' ')}>
          {content.need_extra_info.description}
        </div>
      </>
    )
    break

  case constants.CANDIDATE:
    displayedContent = (
      <>
        <div className={msgClasses.join(' ')}>
          {content.candidate.msg.replace('{name}', patientName)}
        </div>
        <div className={descClasses.join(' ')}>
          {content.candidate.description}
        </div>
      </>
    )
    break

  case constants.DEFAULT_CASE:
    apolloImg = needMoreImg
    apolloImgClasses.push(styles.ApolloMore)
    displayedContent = (
      <>
        <div className={msgClasses.join(' ')}>
          {content.default.msg.replace('{name}', patientName)}
        </div>
        <div className={descClasses.join(' ')}>
          {content.default.description}
        </div>
      </>
    )
    break

  default: break
  }

  const imgClasses = [styles.ApolloImgContainer, globalStyles.Column]
  const msgContainerClasses = [styles.MsgContainer, globalStyles.DoubleColumn]
  return (
    <div className={styles.MainContainer}>
      <div className={imgClasses.join(' ')}>
        <img
          src={apolloImg}
          alt="apollo announcement"
          className={apolloImgClasses.join(' ')}
        />
      </div>
      <div className={msgContainerClasses.join(' ')}>
        {displayedContent}
      </div>
    </div>
  )
}

export default ApolloHeading
