/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import React,
{
  useState, useEffect, useCallback, useContext
} from 'react'
import ErrorPage from '@mymoons/error-page'
import { withRouter } from 'react-router-dom'
import { CustomerContext } from '../../context/context'
import styles from './Diagnostic.module.css'
import globalStyles from '../../index.module.css'
import content from './Diagnostic.content'
import * as constants from '../../Utils/constants'
import Middleware from '../../Api/Middleware'
import Header from '../Header/Header'
import ApolloHeading from '../ApolloHeading/ApolloHeading'
import DoctorStatement from '../DoctorStatement/DoctorStatement'
import CaseDetail from '../CaseDetail/CaseDetail'
import Warning from '../Warning/Warning'
import HowItWorks from '../HowItWorks/HowItWorks'
import TestimonialsCarousel from '../TestimonialsCarousel/TestimonialsCarousel'
import testimonies from '../../Utils/testimonials.json'
import CallToAction from '../CallToAction/CallToAction'
import Footer from '../Footer/Footer'
import Refund from '../Refund/Refund'
import LoaderFullScreen from '../LoaderFullScreen/LoaderFullScreen'
import AuxSticky from '../AuxSticky/AuxSticky'
import Disclaimer from '../Disclaimer/Disclaimer'

/**
 * Diagnostic Component
 * @param {Object} match router match
 * @returns {Void} Diagnostic Component
 */
const Diagnostic = ({ match }) => {
  const [error, setError] = useState(false)
  const [isCTABtnSticky, setisCTASticky] = useState(true)
  const [customerPublicKey, setCustomerPublicKey] = useState('')
  const { customer, changeCustomer } = useContext(CustomerContext)
  const {
    country,
    patientType,
    modality,
    currency,
    appointmentStatus,
    paymentId
  } = customer

  /**
   * Lifecycle DidMount useEffect
   * fetches Customer data by Public Key
   */
  useEffect(() => {
    const params = match && match.params ? match.params : null
    const publicKey = params ? params.id : ''
    const requestBody = {
      table: constants.PATIENT_CLASS,
      columns: {
        PublicKey: publicKey
      }
    }
    setCustomerPublicKey(publicKey)
    Middleware.sendRequest(requestBody, changeCustomer, setError)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * handleCTAContact
   * Handles contacting action for user
   * @param {String} country Customer's country
   * @param {String} patientType Customer's patient type
   * @returns {String} url to new tab to WhatsApp moons contact with pre-message
   */
  const handleCTAContact = useCallback(() => {
    let whatsAppUrl = constants.WHATSAPP_MSG
    switch (country) {
    case 'Colombia':
    case 'colombia':
      switch (patientType) {
      case constants.NO_CANDIDATE:
        whatsAppUrl = whatsAppUrl
          .replace('{moonsNum}', encodeURIComponent(`${constants.WHATSAPP_COUNTRY_CO}${process.env.REACT_APP_WHATS_CO}`))
          .replace('{message}', encodeURIComponent(content.no_candidate))
        break

      default:
        whatsAppUrl = whatsAppUrl
          .replace('{moonsNum}', encodeURIComponent(`${constants.WHATSAPP_COUNTRY_CO}${process.env.REACT_APP_WHATS_CO}`))
          .replace('{message}', encodeURIComponent(content[patientType]))
        break
      }
      break

    default:
      switch (patientType) {
      case constants.NO_CANDIDATE:
        whatsAppUrl = whatsAppUrl
          .replace('{moonsNum}', encodeURIComponent(`${constants.WHATSAPP_COUNTRY_MX}${process.env.REACT_APP_WHATS_MX}`))
          .replace('{message}', encodeURIComponent(content.no_candidate))
        break

      default:
        whatsAppUrl = whatsAppUrl
          .replace('{moonsNum}', encodeURIComponent(`${constants.WHATSAPP_COUNTRY_MX}${process.env.REACT_APP_WHATS_MX}`))
          .replace('{message}', encodeURIComponent(content[patientType]))
        break
      }
      break
    }
    return whatsAppUrl
  }, [
    country, patientType
  ])

  if (error) {
    return <ErrorPage />
  }

  if (Object.keys(customer).length <= 0) {
    return <LoaderFullScreen />
  }

  const actionBtnClasses = [
    constants.NO_CANDIDATE,
    constants.NEED_MEDICAL_TREATMENT,
    constants.NEED_EXTRA_INFO,
    constants.CANDIDATE
  ].includes(patientType)
    ? [styles.ActionBtnContact, styles.AnimationContainer]
    : [styles.ActionBtn, styles.AnimationContainer]
  const btnContainerClasses = [
    constants.NO_CANDIDATE,
    constants.NEED_MEDICAL_TREATMENT,
    constants.NEED_EXTRA_INFO,
    constants.CANDIDATE
  ].includes(patientType)
    ? [styles.BtnContainerContact]
    : [styles.BtnContainer]
  isCTABtnSticky && btnContainerClasses.push(styles.isSticky)
  const bookTextClasses = [globalStyles.Column, styles.BookText]

  let ctaUrl = ''
  if (
    [
      constants.MOONS,
      constants.MOONS_POSSIBLE_ADVANCED,
      constants.MOONS_ADVANCED
    ].includes(patientType)
    && (modality !== constants.MODALITY_HOME_KIT)
    && (appointmentStatus !== constants.APPOINTMENT_SCHEDULED)
  ) {
    ctaUrl = `${
      process.env.REACT_APP_BOOKING_MODULE_URL.replace('[domain]',
        currency === constants.CURRENCY_MX ? 'mx' : 'co')
    }/${customerPublicKey}`
  }

  if (
    [
      constants.NO_CANDIDATE,
      constants.NEED_MEDICAL_TREATMENT,
      constants.NEED_EXTRA_INFO
    ].includes(patientType)
  ) {
    ctaUrl = handleCTAContact()
  }

  if ([constants.CANDIDATE].includes(patientType)) {
    ctaUrl = `${
      process.env.REACT_APP_RETARGET.replace('[domain]',
        currency === constants.CURRENCY_MX ? 'mx' : 'co')
        .replace('[paymentId]', paymentId)
    }`
  }

  return (
    <div className={styles.PageContainer}>
      <Header />
      <div className={styles.Content}>
        <div className={styles.MaxContainer}>
          <ApolloHeading />
          <DoctorStatement />
          {
            ![constants.MOONS, constants.NO_CANDIDATE].includes(patientType)
            && (
              <Warning />
            )
          }
          <CaseDetail />
          <HowItWorks />
        </div>
        {
          ![constants.NO_CANDIDATE].includes(patientType)
          && (
            <TestimonialsCarousel info={testimonies.data} />
          )
        }
        <div className={styles.MaxContainer}>
          {
            [
              constants.NEED_MEDICAL_TREATMENT,
              constants.NEED_EXTRA_INFO,
              constants.CANDIDATE
            ].includes(patientType)
            && <AuxSticky handleIsSticky={setisCTASticky} />
          }
          {
            [constants.NO_CANDIDATE].includes(patientType)
            && (
              <Refund handleIsSticky={setisCTASticky} />
            )
          }
        </div>
        {
          [
            constants.MOONS,
            constants.MOONS_POSSIBLE_ADVANCED,
            constants.MOONS_ADVANCED
          ].includes(patientType)
          && (modality !== constants.MODALITY_HOME_KIT)
          && (appointmentStatus !== constants.APPOINTMENT_SCHEDULED)
          && (
            <>
              <CallToAction handleIsSticky={setisCTASticky} />
              <div className={btnContainerClasses.join(' ')}>
                <div className={styles.MaxContainerCTA}>
                  <div className={bookTextClasses.join(' ')}>
                    {!isCTABtnSticky && content.ctaBookText}
                  </div>
                  <div className={globalStyles.Column}>
                    <a
                      href={ctaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={actionBtnClasses.join(' ')}
                    >
                      <div className={styles.BtnText}>
                        {content.book}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </>
          )
        }
        {
          [
            constants.NO_CANDIDATE,
            constants.NEED_MEDICAL_TREATMENT,
            constants.NEED_EXTRA_INFO
          ].includes(patientType)
          && (
            <div className={btnContainerClasses.join(' ')}>
              <a
                href={ctaUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={actionBtnClasses.join(' ')}
              >
                <div className={styles.BtnText}>
                  {content.contact}
                </div>
              </a>
            </div>
          )
        }
        {
          [constants.CANDIDATE].includes(patientType)
          && (
            <div className={btnContainerClasses.join(' ')}>
              <a
                href={ctaUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={actionBtnClasses.join(' ')}
              >
                <div className={styles.BtnText}>
                  {content.candidate}
                </div>
              </a>
            </div>
          )
        }
        {
          [
            constants.MOONS,
            constants.MOONS_POSSIBLE_ADVANCED,
            constants.MOONS_ADVANCED
          ].includes(patientType)
          && (modality === constants.MODALITY_HOME_KIT)
          && (
            <Warning isMoonsKit />
          )
        }
        {
          [
            constants.MOONS,
            constants.MOONS_POSSIBLE_ADVANCED,
            constants.MOONS_ADVANCED,
            constants.NEED_MEDICAL_TREATMENT
          ].includes(patientType)
          && (
            <Disclaimer isHomeKit={modality === constants.MODALITY_HOME_KIT} />
          )
        }
      </div>
      <Footer />
    </div>
  )
}

export default withRouter(Diagnostic)
