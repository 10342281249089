const templateContent = {
  moons: {
    initial: '{dr_gender} {dr_name} ha determinado que eres candidato al ',
    bold: 'tratamiento moons',
    secondary: 'La complejidad de tu caso es:'
  },
  no_candidate: {
    initial: '{dr_gender} {dr_name} ha determinado que ',
    initial2: ' eres candidato al tratamiento moons',
    bold: 'NO',
    secondary: 'La complejidad de tu caso, no puede ser resuelta con nuestro tratamiento'
  },
  need_medical_treatment: {
    initial: '{dr_gender} {dr_name} ha determinado que para conocer tu diagnóstico, antes debemos realizar algunos procedimientos extra en tus dientes'
  },
  need_extra_info: {
    initial: '{dr_gender} {dr_name} ha determinado que necesitamos más información para diagnosticarte'
  },
  candidate: {
    initial: '{dr_gender} {dr_name} ha pre-diagnosticado que eres candidato al ',
    bold: 'tratamiento moons',
    secondary: 'Recuerda que para tener tu diagnóstico completo debes agendar una cita.'
  },
  default: {
    initial: 'Los doctores moons continúan trabajando tu caso',
    secondary: 'Pronto tendremos más información para ti'
  }
}

export default templateContent
